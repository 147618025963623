
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setBreadCrumbs, setFromBreadCrumbs } from '@/store/composable/BreadCrumbs'
// import { useField, useForm } from "vee-validate";
// import * as yup from "yup";
import PromotionCodeService from "@/core/services/PromotionCodeService";
import IPromotionCode from "@/models/IPromotionCode";
import moment from "moment";
import { useUpdateCustomerData } from "@/store/composable/Customer";

interface AddPromoCodeBodyParam {
  id?: any;
  method?: any;
  promotion_code: any;
  code_value: any;
  code_value_type: any;
  expiry_date: any;
  policy_type: any;
  insurance_type: any;
  status: any;
  campaign_name: any;
  start_date: any;
  max_discount_amount:any;
  usage_type:any;
}

export default defineComponent({
  name: "Add-Promotion-Code",
  props: {},

  data() {
    return {};
  },
  setup() {
    let promotionCode = ref<string>("");
    let codeValue = ref<string>("");
    let expiryDate = ref<string>("");
    let codeValueType = ref<string>("");
    let codeStatus = ref<string>("1");
    let policyType = ref<number>(0);
    let insuranceType = ref<string>("");
    let campaignName = ref<string>("");
    let startDate    = ref<string>("");
    let maxDiscountAmount    = ref<string>("");
    let usageType    = ref<string>("");
    let isPromoExists = ref(false);
    let pTypeDisabled = ref(true);
    const rules = ref({
      promotion_code: {
        show: false,
        message: "Please enter pomotion code",
      },
      code_value: {
        show: false,
        message: "Please enter promotion code value",
      },
      expiry_date: {
        show: false,
        message: "Please enter expiry date",
      },
      code_value_type: {
        show: false,
        message: "Please select promotion code type",
      },
      usage_type: {
        show: false,
        message: "Please select promotion code usage type",
      },
      policy_type: {
        show: false,
        message: "Please select policy type",
      },
      insurance_type: {
        show: false,
        message: "Please select insurance type",
      },
      campaign_name: {
        show: false,
        message: "Please enter campaign name",
      },
      start_date: {
        show: false,
        message: "Please enter start date",
      },
      max_discount_amount: {
        show: false,
        message: "Please enter maximum discount amount",
      },
    });

    const store = useStore();
    const route = useRoute();

    let isFormValid = ref<boolean>(true);

    let addPromoCodeBodyParam = {} as AddPromoCodeBodyParam;
    let promotionCodeTypes = ref<string>("0");
    let editPromotionCodeObj = ref<IPromotionCode>();
    addPromoCodeBodyParam = {
      promotion_code: "",
      code_value: "",
      code_value_type: "",
      expiry_date: "",
      usage_type: "2",
      policy_type: "1",
      insurance_type: 1,
      status: "1",
      campaign_name:"",
      start_date:"",
      max_discount_amount:"0"
    };

    onMounted(() => {
      toFirst()
      MenuComponent.reinitialization();
      
      let breadcrumb     = setBreadCrumbs(route.name)
      let fromBreadcrumb = setFromBreadCrumbs(route.name)
      setCurrentPageBreadcrumbs(breadcrumb, fromBreadcrumb)

      Promise.all([PromotionCodeService.getPromotionCodeTypes()]).then(
        (data) => {
          promotionCodeTypes.value = store.getters.domPromotionCodeTypes;
        }
      );

      if (route.params.id !== undefined && route.params.id != null) {
        let params = {
          promotion_code_id: route.params.id,
        };

        Promise.all([PromotionCodeService.showPromotionCode(params)]).then(
          (data) => {
            let editPromotionCodeObj = store.getters.editPromotionCode;
            promotionCode.value = editPromotionCodeObj.promotion_code;
            codeValue.value = editPromotionCodeObj.code_value;
            codeValueType.value = editPromotionCodeObj.code_value_type;
            usageType.value = editPromotionCodeObj.usage_type;
            policyType.value = editPromotionCodeObj.policy_type;
            insuranceType.value = editPromotionCodeObj.insurance_type;
            expiryDate.value = editPromotionCodeObj.expiry_date;
            codeStatus.value = editPromotionCodeObj.status;
            campaignName.value = editPromotionCodeObj.campaign_name;
            startDate.value = editPromotionCodeObj.start_date;
            maxDiscountAmount.value = (editPromotionCodeObj.code_value_type == 1) ? editPromotionCodeObj.max_discount_amount : 0;
            if(editPromotionCodeObj.insurance_type == 3) {
              pTypeDisabled.value = false;
            } else {
              pTypeDisabled.value = true;
            }
            addPromoCodeBodyParam = {
              promotion_code: editPromotionCodeObj.promotion_code,
              code_value: editPromotionCodeObj.code_value,
              code_value_type: editPromotionCodeObj.code_value_type,
              expiry_date: editPromotionCodeObj.expiry_date,
              usage_type: editPromotionCodeObj.usage_type,
              policy_type: editPromotionCodeObj.policy_type,
              insurance_type: editPromotionCodeObj.insurance_type,
              status: editPromotionCodeObj.status,
              campaign_name:editPromotionCodeObj.campaign_name,
              start_date:editPromotionCodeObj.start_date,
              max_discount_amount:editPromotionCodeObj.max_discount_amount,
            };
          }
        );
      }
    });

    const onChangeStatus = (event) => {
      addPromoCodeBodyParam.status = event.target.value;
    };

    const getDateTime = (dateTime,type='expiry') => {
      const dateformart = moment(dateTime, 'DD/MM/YYYY', true).isValid() 
      ? moment(dateTime.target.value, 'DD/MM/YYYY').format('YYYY-MM-DD') 
      : dateTime;
      var date = new Date(dateformart);
        
      if(type == 'expiry'){
        addPromoCodeBodyParam.expiry_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      }
      if(type == 'start'){
        addPromoCodeBodyParam.start_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      }

    };

    const validateAddPromoCode = () => {
      if (promotionCode.value == "") {
        rules.value.promotion_code.show = true;
        isFormValid.value = false;
      }
      if (codeValue.value == "") {
        rules.value.code_value.show = true;
        isFormValid.value = false;
      }
      if (expiryDate.value == "") {
        rules.value.expiry_date.show = true;
        isFormValid.value = false;
      }

      if (codeValueType.value == "") {
        rules.value.code_value_type.show = true;
        isFormValid.value = false;
      }
      if (usageType.value == "") {
        rules.value.usage_type.show = true;
        isFormValid.value = false;
      }
      if (!policyType.value) {
        rules.value.policy_type.show = true;
        isFormValid.value = false;
      }
      if (addPromoCodeBodyParam.insurance_type == "") {
        rules.value.insurance_type.show = true;
        isFormValid.value = false;
      }

      if (campaignName.value == "") {
        rules.value.campaign_name.show = true;
        isFormValid.value = false;
      }

      if (startDate.value == "") {
        rules.value.start_date.show = true;
        isFormValid.value = false;
      }

      if (maxDiscountAmount.value == "" && codeValueType.value == "1") {
        rules.value.max_discount_amount.show = true;
        isFormValid.value = false;
      }

      if(isPromoExists.value){
        rules.value.promotion_code.show = true;
        isFormValid.value = false;
      }

    };
    const addPromotionCode = () => {
      isFormValid.value = true;
      
      getDateTime(expiryDate.value,'expiry');
      getDateTime(startDate.value,'start');
      validateAddPromoCode();

      if (isFormValid.value) {
        assignAddPromoCodeParam();

        if (route.params.id !== undefined && route.params.id != null) {
          addPromoCodeBodyParam.id = route.params.id;
          PromotionCodeService.updatePromotionCode(addPromoCodeBodyParam);
        } else {
          PromotionCodeService.addPromotionCode(addPromoCodeBodyParam);
        }
      } else {
        focusField()
      }
    };  


    const assignAddPromoCodeParam = () => {
      addPromoCodeBodyParam.promotion_code = promotionCode.value;
      addPromoCodeBodyParam.code_value = codeValue.value;
      addPromoCodeBodyParam.campaign_name = campaignName.value;

      // if percentage accept value else if flat max will be 0
      addPromoCodeBodyParam.max_discount_amount = (codeValueType && codeValueType.value == '1') ? maxDiscountAmount.value : 0;
    };

    const onCodeValueTypeChange = (event) => {
      addPromoCodeBodyParam.code_value_type = event;
      rules.value.code_value_type.show = false
    };

    const onUsageTypeChange = (value) => {
      rules.value.usage_type.show=false;
      addPromoCodeBodyParam.usage_type = value;
      usageType.value = value;
    };

    const onPolicyTypeChange = (event) => {
      addPromoCodeBodyParam.policy_type = event;
      rules.value.policy_type.show = false
      // policyType.value = event.target.value;
    };

    const onInsuranceTypeChange = (event) => {
      addPromoCodeBodyParam.insurance_type = event.target.value;
      insuranceType.value = event.target.value;
      pTypeDisabled.value = true;
      if(event.target.value == 3) {
        addPromoCodeBodyParam.policy_type = 3 // Both
        policyType.value = 3
        pTypeDisabled.value = false;
      }
    };

    const disabledDate = (time: Date) => {
      return Date.now() > time.getTime();
    };

    const inst = getCurrentInstance()
    const toFirst = () => {
      setTimeout(() => {
          const codeRef:any = inst?.refs?.codeRef
          codeRef.focus()
      }, 500);
    }

    const toCode = () => {
      const codeRef:any = inst?.refs?.codeRef
      codeRef.focus()
    }

    const toPromoValue = () => {
      const promoValue:any = inst?.refs?.promoValue
      promoValue.focus()
    }

    const toCodeType = () => {
      const codeType:any = inst?.refs?.codeType
      codeType.focus()
    }

    const toDate = () => {
      const todate:any = inst?.refs?.todate
      todate.isFocus()
    }

    const toPolicyType = () => {
      const policyTypeRef:any = inst?.refs?.policyTypeRef
      policyTypeRef.focus()
    }

    const toPromoCodeUsage = () => {
      const usageTypeRef:any = inst?.refs?.usageTypeRef
      usageTypeRef.focus()
    }

    const focusField = () => {
      const { promotion_code, expiry_date, code_value, code_value_type } = rules.value

      if(promotion_code.show) {
        toCode()
      }

      if(!promotion_code.show && expiry_date.show) {
        toDate()
      }

      if(!promotion_code.show && !expiry_date.show && code_value.show) {
        toPromoValue()
      }

      if(!promotion_code.show && !expiry_date.show && !code_value.show && code_value_type.show) {
        toCodeType()
      }

      if(!promotion_code.show && !expiry_date.show && !code_value.show && !code_value_type.show) {
        toPolicyType()
      }
    }


    const expiryMinDate = computed(()=>{
      return startDate.value || '';
    });

    const showMaxDiscountAmount = computed(()=>{
      return (codeValueType.value == '1');
    });

    const allowNumberOnly = (type)=>{
      rules.value[type].show = false;
      const regex = /^\d+$/;
      if(type == 'code_value'){
        if (!regex.test(codeValue.value)) {
          codeValue.value = codeValue.value.slice(0, -1);
        }
      }

      if(type == 'max_discount_amount'){
        if (!regex.test(maxDiscountAmount.value)) {
          maxDiscountAmount.value = maxDiscountAmount.value.slice(0, -1);
        }
      }
    }

    const trimSpaces = (ruleVal='') => {
      if(ruleVal){
        rules.value[ruleVal].show = false;
      }
      if(ruleVal == 'promotion_code'){
        promotionCode.value = promotionCode.value.replace(/\s+/g, '');
      }
    }


    const clearExpiryDate = () =>{
      rules.value.start_date.show = false
      expiryDate.value = '';
    }

    const toStartDate = () =>{
      const startdate:any = inst?.refs?.startdate
      startdate.focus()
    };

    const toCampaignName = () =>{
      const codeRef:any = inst?.refs?.codeRef
      codeRef.focus()
    };

    const toExpiryDate = () =>{
      const todate:any = inst?.refs?.todate
      todate.focus()
    };

    
    const toMaxDiscountAmount = (tabType='forward') => {
      if(codeValueType.value.toString() !== '1'){
        if(tabType == 'back'){ toPromoValue(); }
        else{ toPolicyType(); }
      }else{
        const maxDiscountAmountRef:any = inst?.refs?.maxDiscountAmountRef
        maxDiscountAmountRef.focus()
      }
    }

    async function validatePromoCode(){
      if(promotionCode.value){
        rules.value.promotion_code.show   = false;

        const response = await useUpdateCustomerData({
            promo_code: promotionCode.value,
            link: '/skye/promotions/validate/exists',
        });

        if (typeof(response.status) == 'undefined' || response.status !== 200) {
          rules.value.promotion_code.message = "Promotion code already exists" 
          rules.value.promotion_code.show   = true;
          isFormValid.value = false;
          // promotionCode.value="";
          isPromoExists.value = true;
        }else{
          isPromoExists.value = false;
        }

      }
    };

    return {
      toPromoCodeUsage,
      trimSpaces,
      allowNumberOnly,
      toPolicyType,
      focusField,
      toDate,
      toCodeType,
      toCode,
      toPromoValue,
      toFirst,
      addPromoCodeBodyParam,
      addPromotionCode,
      onChangeStatus,
      rules,
      isFormValid,
      promotionCodeTypes,
      disabledDate,
      getDateTime,
      codeValueType,
      expiryDate,
      promotionCode,
      codeValue,
      codeStatus,
      onCodeValueTypeChange,
      usageType,
      onUsageTypeChange,
      policyType,
      onPolicyTypeChange,
      insuranceType,
      onInsuranceTypeChange,
      editPromotionCodeObj,
      campaignName,
      startDate,
      expiryMinDate,
      showMaxDiscountAmount,
      maxDiscountAmount,
      clearExpiryDate,
      toStartDate,
      toCampaignName,
      toExpiryDate,
      toMaxDiscountAmount,
      validatePromoCode,
      pTypeDisabled,
    };
  },
});
